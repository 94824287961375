<template>
  <base-section id="verses" class="pb-10">
    <base-section-heading icon="mdi-book-open-page-variant" :title="title" />
    <v-container>
      <v-row justify="center">
        <v-col v-for="card in cards" :key="card.title" cols="12" md="4">
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
    <v-row>
      <v-col class="text-center">
        <feedback :value="entry" />
      </v-col>
    </v-row>
  </base-section>
</template>

<script>
// Extensions
import verses from "@/lib/verses";

export default {
  name: "HelpEntry",
  components: {
    Feedback: () => import("@/views/sections/Feedback"),
    LocaleSelect: () => import("@/views/sections/LocaleSelect")
  },
  props: {
    entry: {
      type: String,
      default: () => undefined
    }
  },

  computed: {
    title() {
      return (
        "What does the Bible say about " +
        this.entry.replace("-", " & ") +
        "..."
      );
    },
    cards() {
      return this?.entry ? verses[this.entry] : [];
    }
  }
};
</script>
